import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./Login";
import Dashboard from "./Dashboard";
import Clients from "./Clients";
import EditMessages from "./EditMessages";

function App() {
  return (
    <Router>
      <Routes>
        {/* Login Route */}
        <Route path="/login" element={<Login />} />

        {/* Dashboard Route */}
        <Route path="/dashboard" element={<Dashboard />} />

        {/* Clients Route */}
        <Route path="/clients" element={<Clients />} />

        {/* Redirect to Login or Dashboard */}
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/edit-messages" element={<EditMessages />} />
      </Routes>
    </Router>
  );
}

export default App;

import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Hidden,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/People";
import LogoutIcon from "@mui/icons-material/Logout";
import logo from "../src/assets/logo.png";
import { PRIMARY_COLOR } from "./assets/Colours";

const Sidebar = ({ open, onClose }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("businessID");
    localStorage.removeItem("permissionID");
    navigate("/login");
  };

  const drawerWidth = 290;

  const menuItems = [
    { text: "Dashboard", icon: <DashboardIcon />, path: "/dashboard" },
    { text: "Clients", icon: <ShoppingCartIcon />, path: "/clients" },
  ];

  const drawerContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        bgcolor: PRIMARY_COLOR,
        color: "black",
        width: drawerWidth,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 200,
          padding: "16px",
          overflow: "hidden",
        }}
      >
        <img
          src={logo}
          alt="Excellar Wine"
          style={{
            height: "150px",
            objectFit: "contain",
          }}
        />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <List>
          {menuItems.map((item) => (
            <ListItem
              button
              key={item.text}
              onClick={() => navigate(item.path)}
              sx={{
                ml: "15px",
                "& span, & svg": {
                  fontFamily: "Jua",
                },
                "& span": {
                  fontSize: "21px",
                },
                "&:hover": {
                  bgcolor: "white",
                  color: "#F1AC36",
                  borderRadius: "10px",
                  transition: "transform 0.3s",
                  transform: "translateY(-5px)",
                  "& .MuiListItemIcon-root": {
                    color: "#F1AC36",
                  },
                },
              }}
            >
              <ListItemIcon sx={{ color: "#F1AC36" }}>
                {React.cloneElement(item.icon, { sx: { fontSize: "30px" } })}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
        <List>
          <ListItem
            button
            onClick={handleLogout}
            sx={{
              ml: "20px",
              "& span, & svg": {
                fontFamily: "Jua",
              },
              "& span": {
                fontSize: "21px",
              },
              "&:hover": {
                bgcolor: "white",
                color: "#F1AC36",
                transition: "transform 0.5s",
                transform: "translateY(-5px)",
                "& .MuiListItemIcon-root": {
                  color: "#F1AC36",
                },
              },
            }}
          >
            <ListItemIcon sx={{ color: "#F1AC36" }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Hidden mdDown>
        <Box
          sx={{
            width: drawerWidth,
            position: "fixed",
            height: "100vh",
            top: 0,
            left: 0,
          }}
        >
          {drawerContent}
        </Box>
        <Box sx={{ width: drawerWidth, flexShrink: 0 }} />
      </Hidden>
      <Hidden mdUp>
        <Drawer
          variant="persistent"
          anchor="left"
          open={open}
          onClose={onClose}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              height: "100vh",
              position: "fixed",
            },
          }}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
    </Box>
  );
};

export default Sidebar;

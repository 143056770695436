import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, TextField, Button, Typography } from "@mui/material";

const EditMessages = () => {
  const [systemMessage, setSystemMessage] = useState("");
  const [coldCallScript, setColdCallScript] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    // Fetch the current system message and cold call script
    const fetchMessages = async () => {
      try {
        const response = await axios.get("/api/TwilioOpenAI/get-messages");
        setSystemMessage(response.data.systemMessage);
        setColdCallScript(response.data.coldCallScript);
      } catch (error) {
        console.error("Error fetching messages:", error);
        setStatus("Failed to fetch messages.");
      }
    };

    fetchMessages();
  }, []);

  const handleUpdateSystemMessage = async () => {
    try {
      await axios.post("/api/TwilioOpenAI/update-system-message", {
        message: systemMessage,
      });
      setStatus("System message updated successfully.");
    } catch (error) {
      console.error("Error updating system message:", error);
      setStatus("Failed to update system message.");
    }
  };

  const handleUpdateColdCallScript = async () => {
    try {
      await axios.post("/api/TwilioOpenAI/update-cold-call-script", {
        message: coldCallScript,
      });
      setStatus("Cold call script updated successfully.");
    } catch (error) {
      console.error("Error updating cold call script:", error);
      setStatus("Failed to update cold call script.");
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Edit Messages
      </Typography>

      <Typography variant="h6" sx={{ mt: 2 }}>
        System Message
      </Typography>
      <TextField
        multiline
        fullWidth
        rows={6}
        value={systemMessage}
        onChange={(e) => setSystemMessage(e.target.value)}
        sx={{ mt: 1 }}
      />
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={handleUpdateSystemMessage}
      >
        Update System Message
      </Button>

      <Typography variant="h6" sx={{ mt: 4 }}>
        Cold Call Script
      </Typography>
      <TextField
        multiline
        fullWidth
        rows={6}
        value={coldCallScript}
        onChange={(e) => setColdCallScript(e.target.value)}
        sx={{ mt: 1 }}
      />
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={handleUpdateColdCallScript}
      >
        Update Cold Call Script
      </Button>

      {status && (
        <Typography sx={{ mt: 2, color: "green" }}>{status}</Typography>
      )}
    </Box>
  );
};

export default EditMessages;

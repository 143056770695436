import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import Sidebar from "./Sidebar";

const Clients = () => {
  const [clients, setClients] = useState([]); // Client list
  const [totalClients, setTotalClients] = useState(0); // Total clients
  const [callStatus, setCallStatus] = useState(""); // Status of call actions
  const [dialogOpen, setDialogOpen] = useState(false); // Dialog open state
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(""); // Phone number of the selected client
  const [script, setScript] = useState({
    firstMessage: "",
    introduction: "",
  }); // Store the full script dynamically

  // Backend API URLs
  const TWILIO_BASE_API_URL =
    "https://6e22efa8-98b5-4be0-8116-dbaad56003e0-00-3ncw2ixefij90.riker.replit.dev";
  const AZURE_BASE_API_URL =
    "https://dobbythefreeapi20241205175027.azurewebsites.net/api";
  const CLIENTS_API = `${AZURE_BASE_API_URL}/client`; // Update this to your clients API endpoint if needed
  const SCRIPT_API = `${TWILIO_BASE_API_URL}/assistant-script`;

  // Fetch clients and script from the backend
  const fetchData = async () => {
    try {
      // Fetch clients
      const clientsResponse = await axios.get(CLIENTS_API);

      // Fetch script
      const scriptResponse = await axios.get(SCRIPT_API);

      // Update state
      setClients(clientsResponse.data);
      setTotalClients(clientsResponse.data.length);
      setScript({
        firstMessage: scriptResponse.data.assistant.content, // Use the assistant's message
        introduction: scriptResponse.data.system.content, // Use the system's message
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Open dialog for initiating a call
  const handleOpenDialog = (phoneNumber) => {
    setSelectedPhoneNumber(phoneNumber);
    setDialogOpen(true);
  };

  // Close dialog
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // Save the updated script to the backend
  const handleSaveScript = async () => {
    try {
      await axios.post(SCRIPT_API, {
        systemMessage: script.introduction,
        assistantMessage: script.firstMessage,
      });
      setCallStatus("Script updated successfully.");
    } catch (error) {
      console.error("Error updating script:", error);
      setCallStatus("Failed to update script.");
    }
  };

  // Initiate the call
  const handleSaveAndCall = async () => {
    try {
      await axios.post(`${TWILIO_BASE_API_URL}/outgoing-call/`, {
        firstMessage: script.firstMessage,
        number: selectedPhoneNumber,
      });

      setCallStatus("Call initiated successfully.");
      setDialogOpen(false); // Close the dialog after saving and calling
    } catch (error) {
      console.error("Error initiating call:", error);
      setCallStatus("Failed to initiate call.");
    }
  };

  return (
    <Box sx={{ display: "flex", pr: "100px" }}>
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 3,
        }}
      >
        <Container>
          {/* Header */}
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: "black",
              fontFamily: "Jua",
              fontWeight: 900,
              textAlign: "left",
            }}
          >
            Clients
          </Typography>

          {/* Total Clients Card */}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ height: "130px", fontFamily: "Jua" }}>
                <CardContent>
                  <Typography sx={{ fontFamily: "Jua" }} variant="h6">
                    Total Clients
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#25467B",
                      fontWeight: 400,
                      fontFamily: "Jua",
                    }}
                  >
                    {totalClients}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Client Table */}
          <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
            All Clients
          </Typography>
          <TableContainer component={Paper} sx={{ maxHeight: 800 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Client ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Call</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clients.map((client) => (
                  <TableRow key={client.client_id}>
                    <TableCell>{client.client_id}</TableCell>
                    <TableCell>{client.name}</TableCell>
                    <TableCell>{client.email}</TableCell>
                    <TableCell>{client.phone}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={() => handleOpenDialog(client.phone)}
                      >
                        Call
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Call Status */}
          {callStatus && (
            <Typography variant="subtitle1" sx={{ mt: 2, color: "green" }}>
              {callStatus}
            </Typography>
          )}

          {/* Dialog for Editing Script and Initiating Call */}
          {/* Dialog for Editing Script and Initiating Call */}
          <Dialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="md"
            sx={{
              "& .MuiDialog-paper": {
                borderRadius: "12px",
                padding: "16px",
                maxHeight: "90vh",
              },
            }}
          >
            <DialogTitle
              sx={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                bgcolor: "#f5f5f5",
                borderBottom: "1px solid #ddd",
                padding: "16px",
                mb: 2,
              }}
            >
              Edit Script and Initiate Call
            </DialogTitle>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                padding: "16px 24px",
              }}
            >
              <TextField
                label="Introduction"
                multiline
                fullWidth
                rows={4}
                value={script.introduction}
                onChange={(e) =>
                  setScript({ ...script, introduction: e.target.value })
                }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                }}
              />
              <TextField
                label="First Message"
                multiline
                fullWidth
                rows={10}
                value={script.firstMessage}
                onChange={(e) =>
                  setScript({ ...script, firstMessage: e.target.value })
                }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                }}
              />
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "space-between",
                padding: "16px 24px",
              }}
            >
              <Button
                onClick={handleSaveScript}
                sx={{
                  textTransform: "none",
                  bgcolor: "#f0f0f0",
                  color: "#000",
                  borderRadius: "8px",
                  "&:hover": {
                    bgcolor: "#ddd",
                  },
                }}
              >
                Save Script
              </Button>
              <Box>
                <Button
                  onClick={handleCloseDialog}
                  sx={{
                    textTransform: "none",
                    bgcolor: "#f0f0f0",
                    color: "#000",
                    borderRadius: "8px",
                    "&:hover": {
                      bgcolor: "#ddd",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSaveAndCall}
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    bgcolor: "#1976d2",
                    color: "#fff",
                    borderRadius: "8px",
                    ml: 2,
                    "&:hover": {
                      bgcolor: "#1565c0",
                    },
                  }}
                >
                  Call
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        </Container>
      </Box>
    </Box>
  );
};

export default Clients;
